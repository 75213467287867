import React, { useEffect } from 'react';
import AOS from 'aos';

// routes
import Routes from './routes/Routes';
import CookieConsentBanner from './CookieConsentBanner'; // Adjust the import path as necessary

// Themes
import './assets/scss/theme.scss';

// Import ThemeProvider
import { ThemeProvider } from './ThemeContext'; // Adjust the path as needed

const App: React.FC = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <ThemeProvider>
            {/* <CookieConsentBanner /> */}
            <Routes />
        </ThemeProvider>
    );
};

export default App;
